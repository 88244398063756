<template>
  <div class="chart-wrapper" v-if="getBookTotal > 0">
    <Doughnut
        :chart-options="chartOptions"
        :chart-data="chartData"
        class='doughnut-wrapper'
    />
    <div class='legend-wrapper'>
      <ul>
        <li v-for="(genre, index) in chartData.labels" :key="index">
          <div class='genre-bullet' :style="{'backgroundColor': chartData.colors[index]}"></div>
          <div class='genre-name'>{{ $t('genres.' + genre.replace(/\./g, "")) }}<span class='genre-percentage'>{{ Math.round(chartData.booksRead[index] / getBookTotal * 100) }}%</span></div>
          
        </li>
      </ul>
    </div>
  </div>
  <div class='chart-wrapper' v-else-if="getBookTotal === 0">
    <Doughnut
        :chart-data="{        
          datasets: [{data: [1]}]}"
        class='doughnut-wrapper'
    />
    <div class='legend-wrapper'>
      <p class="text-muted" style="fontWeight: 500">{{$t('statsPanel.emptyGenresRead')}}</p>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import {Doughnut} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Plugin
} from 'chart.js'
import logService from "@/app/services/LogService";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

// How many genres can we show on the chart?
const maxGenres = 5;

// Should have at least that # of colors here...
const genreColors = ['#18DA5A', '#D316E3', '#FF0651', '#FFE819', '#3322FF'];


export default {
  name: 'GenreChart',
  components: {Doughnut},
  props: ['statsPage'],
  computed: {
    genreData() {
      return logService.getGenreStats(maxGenres);
    },
    getBookTotal() {
      return this.genreData.reduce((a, b) => a + b.value, 0)
    },
    chartData() {
      return {
        labels: this.genreData.map(x => {
          if (x.name === 'N/A') {
            return 'Other'
          } else if (x.name !== 'N/A') {
            return x.name
          }
        }),
        colors: genreColors,
        booksRead: this.genreData.map(x => x.value),
        datasets: [
          {
            backgroundColor: genreColors,
            data: this.genreData.map(x => x.value)
          }
        ]
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return this.$t('genres.' + context.label.replace(/\./g, ""))
              }.bind(this)
            }
          }
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.chart-wrapper {
  display: flex;
  align-items: center;

  .doughnut-wrapper {
    width: 8vw;
    min-width: 100px;
  }

  .legend-wrapper {
    display: flex;
    align-items: center;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 5px 0;
      position: relative;
      min-width: 125px;

      .genre-bullet {
        height: 15px;
        min-width: 15px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .genre-name {
        font-size: clamp(12px, 14px, 16px);
        display: inline-flex;
        font-weight: 500;
      }

      .genre-percentage {
        color: rgba(0, 0, 0, 0.55);
        font-size: clamp(12px, 14px, 16px);
        margin: auto 5px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .chart-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
}

</style>